import React from "react";
import { Helmet } from "react-helmet-async";
import background from "../assets/background.png";

const Meta = () => (
  <Helmet>
    <title>formsieben | new website coming soon</title>
    <meta name="robots" content="index, follow"></meta>
    <meta
      name="description"
      content="A new chapter in our fairytale is about to begin! Our magical website, filled with creative solutions and enchanting designs, is coming soon."
    />
    <meta
      name="keywords"
      content="formsieben, corporate design, web design, branding, logo design,"
    />
    <meta name="author" content="FORMSIEBEN GMBH" />
    <meta property="og:title" content="formsieben | new website coming soon" />
    <meta
      property="og:description"
      content="A new chapter in our fairytale is about to begin! Our magical website, filled with creative solutions and enchanting designs, is coming soon. Stay tuned for more!"
    />
    <meta property="og:image" content={background} />
    <meta property="og:url" content="https://www.formsieben.de" />
    <meta name="twitter:card" content="summary_large_image" />
  </Helmet>
);

export default Meta;
