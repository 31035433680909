import React from "react";
import { HelmetProvider } from "react-helmet-async";
import Meta from "./components/Meta";
import "./App.css";
import video from "./assets/video.webm";
import background from "./assets/background.png";

function App() {
  return (
    <HelmetProvider>
      <div
        className="min-h-screen w-full bg-cover bg-center flex items-center justify-center"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Meta />
        <div className="max-w-screen-xl w-full flex flex-col md:flex-row h-full p-4">
          <div className="w-full md:w-1/2 flex items-center justify-center">
            <video
              className="w-full h-[26rem] md:h-[80%] object-cover"
              src={video}
              autoPlay
              muted
              playsInline
            />
          </div>
          <div className="w-full md:w-1/2 flex flex-col items-center justify-center p-4 md:p-8 text-center md:text-left ">
            <p className="w-full text-lg md:text-xl mb-4 uppercase font-roboto font-light tracking-wider">
              A new chapter in our fairytale is about to begin! Our magical
              website, filled with creative solutions and enchanting designs, is
              coming soon.
            </p>
            <p className="text-lg md:text-xl w-full mb-6 uppercase font-roboto font-light tracking-wider">
              Curious? Stay tuned …
            </p>
            <p className="w-full text-sm font-roboto font-thin text-gray-800">
              FORM<strong>SIEBEN</strong> GMBH
              <br />
              Annastrasse 13 · 64390 Erzhausen, Germany
              <br />
              phone: +49 172 6959027 · mail: info@formsieben.com
              <br />
              HRB 8521 District Court Darmstadt · USt. IdNr.: DE224250995
            </p>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
